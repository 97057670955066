
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";

export default defineComponent({
  components: {
    Grid,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    const gridOptions: GridOptions = {
      id: "reply-template",
      title: "回應內容樣板",
      multiselect: false,
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      columns: [
        {
          field: "Type",
          title: "問題類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.ContactUsType).find(e => e.Value === cellValue)?.Name : undefined
        },
        { field: "Content", title: "內容", formatter: ({ cellValue }) => cellValue?.substring(0, 20) },
      ],
      promises: {
        query: model ? (params) => model.dispatch("replyTemplate/query", params) : undefined,
        queryAll: model ? () => model.dispatch("replyTemplate/query") : undefined,
        save: model ? (params) => model.dispatch("replyTemplate/save", params) : undefined,
      },
      modalConfig: { width: "80%", showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      items: [
        { field: "Type", title: "問題類型", span: 24, itemRender: { name: "$select", props: { placeholder: "問題類型" }, options: model ? Object.values(model.enums.ContactUsType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "Content", title: "內容", span: 24, itemRender: { name: "$textarea", props: { placeholder: "請輸入內容", autosize: { minRows: 8 } } } },
      ],
      rules: {
        Type: [{ required: true }],
        Content: [{ required: true }],
      }
    };

    return {
      grid,
      gridOptions,
      formOptions
    };
  }
});
